<template>
  <v-sheet width="320px">
    <v-row dense>
      <v-col cols="12">
        <v-select
          v-model="camera"
          :label="$t('Camera')"
          item-text="label"
          :items="listCamera"
          return-object
          @change="changeCamera"
        />
      </v-col>
      <v-col cols="12">
        <web-cam ref="cam" :resolution="resolution" height="180" @cameras="listCameras" />
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import { BrowserMultiFormatReader } from "@zxing/browser"
import { WebCam } from "vue-web-cam"
export default {
  name: "ScreamBarcodeReader",
  components: { WebCam },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    camera: null,
    deviceId: null,
    controls: null,
    listCamera: [],
    resolution: { width: 1920, height: 1080 }
  }),
  watch: {
    show() {
      //this.$log("show ", this.show)
      if (this.show) {
        this.startRead()
      } else {
        this.controls.stop()
      }
    }
  },
  methods: {
    changeCamera(e) {
      //this.$log("changeCamera ", e)
      this.controls.stop()
      this.camera = e
      this.deviceId = e.deviceId
      this.startRead()
    },
    listCameras(e) {
      this.listCamera = e
      if (this.listCamera.length > 0) {
        this.camera = this.listCamera[0]
        this.deviceId = this.camera.deviceId
        this.startRead()
      }
    },
    startRead() {
      //console.log("startRead")
      const codeReader = new BrowserMultiFormatReader()
      codeReader.decodeFromVideoDevice(this.deviceId, this.$refs.cam.$el, (result, error, controls) => {
        this.controls = controls
        if (result) {
          //console.log("read1 ", result)
          this.$emit("read", result.text)
          controls.stop()
        }
      })
    }
  }
}
</script>
<style scoped></style>

<template>
  <base-material-card class="mb-0" color="success" inline icon="mdi-swap-horizontal" :title="$t('Circulație')">
    <v-row :dense="dense">
      <v-col cols="12" sm="6">
        <v-row :dense="dense" class="text-no-wrap" align="center">
          <v-col class="text-right grey--text" cols="5" md="4">
            {{ $t("Barcode utilizator") }}
          </v-col>
          <v-col cols="7" md="8">
            <v-text-field
              v-model="barcode"
              :dense="dense"
              clearable
              @click:clear="closeSheet"
              @keydown.enter="searchReader"
              @keydown.tab="searchReader"
            >
              <template #append>
                <v-icon class="pt-1" tabindex="-1" @click.prevent="searchRelation">
                  mdi-magnify
                </v-icon>
                <v-menu v-model="readerCam" :close-on-content-click="false" offset-y>
                  <template #activator="{ on: onMenu }">
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-icon class="pt-1 ml-2" v-on="{ ...onMenu, ...on }">
                          mdi-barcode-scan
                        </v-icon>
                      </template>
                      <span>{{ $t("Citire barcode cu camera web") }}</span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item>
                      <s-barcode-reader
                        :show="readerCam"
                        @read="
                          readerCam = false
                          barcode = $event
                          searchReader()
                        "
                      />
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row :dense="dense" class="text-no-wrap ">
          <v-col class="text-right grey--text pt-4" cols="5" md="4">
            {{ $t("Barcode inventar") }}
          </v-col>
          <v-col cols="7" md="8">
            <v-text-field
              ref="barcodeInventar"
              v-model="barcodeInventar"
              :dense="dense"
              :readonly="!barcodeUser"
              @keydown.enter="loanEnter"
            >
              <template #append>
                <v-icon class="pt-1" tabindex="-1" @click.prevent="searchRelationInv">
                  mdi-magnify
                </v-icon>
                <v-menu v-model="inventarCam" :close-on-content-click="false" offset-y>
                  <template #activator="{ on: onMenu }">
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-icon class="pt-1 ml-2" v-on="{ ...onMenu, ...on }">
                          mdi-barcode-scan
                        </v-icon>
                      </template>
                      <span>{{ $t("Citire barcode cu camera web") }}</span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item>
                      <s-barcode-reader
                        :show="inventarCam"
                        @read="
                          inventarCam = false
                          barcodeInventar = $event
                          loanEnter()
                        "
                      />
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" class="d-flex">
            <v-tooltip top>
              <template #activator="{ on }">
                <div v-on="on">
                  <v-checkbox v-model="autoLoan" class="mt-0" dense hide-details :label="$t('Împrumut automat')" />
                </div>
              </template>
              <span>{{
                $t(
                  "La apăsarea tastei Enter în câmpul barcode inventar sau la citirea cu codului de bare cu cititorul de barcode, se v-a face automat împrumutul sau restituirea."
                )
              }}</span>
            </v-tooltip>

            <v-spacer />
            <v-btn class="mr-2 px-2" color="success" small @click="loan">{{ $t("Împrumută/Restituie") }}</v-btn>
            <v-btn class="px-2" color="info" small outlined @click="extend">{{ $t("Prelungește") }}</v-btn>
            <v-btn class="ml-2 px-2" color="warning" outlined small @click="reserve">{{ $t("Rezervare") }}</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8" sm="4">
        <v-simple-table class="mt-0" dense>
          <template #default>
            <tbody>
              <tr>
                <td class="font-weight-bold">{{ $t("Nume") }}</td>
                <td>{{ name }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t("Tip") }}</td>
                <td>{{ type }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t("Card") }}</td>
                <td>{{ card }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t("Cont") }}</td>
                <td>{{ cont }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t("Înscriere") }}</td>
                <td>{{ insert | limitData }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t("Ultima vizare") }}</td>
                <td>{{ visa }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <!-- <v-row :dense="dense">
                <v-col cols="12" class="d-flex justify-center">
                  <span>
                    {{ name }}
                  </span>
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                  {{ type }}
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                  <v-tooltip v-if="card == 'Înscris'" top>
                    <template v-slot:activator="{ on }">
                      <v-icon color="success" v-on="on">
                        mdi-credit-card
                      </v-icon>
                    </template>
                    <span>{{ card }}</span>
                  </v-tooltip>
                  <v-tooltip v-if="card == 'Reînscris'" top>
                    <template v-slot:activator="{ on }">
                      <v-icon color="success" v-on="on">
                        mdi-credit-card-check
                      </v-icon>
                    </template>
                    <span>{{ card }}</span>
                  </v-tooltip>
                  <v-tooltip v-if="card == 'Suspendat'" top>
                    <template v-slot:activator="{ on }">
                      <v-icon color="success" v-on="on">
                        mdi-credit-card-clock
                      </v-icon>
                    </template>
                    <span>{{ card }}</span>
                  </v-tooltip>
                  <v-tooltip v-if="card == 'Expirat'" top>
                    <template v-slot:activator="{ on }">
                      <v-icon color="success" v-on="on">
                        mdi-credit-card-off
                      </v-icon>
                    </template>
                    <span>{{ card }}</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                  <v-tooltip v-if="cont == 'Activ'" top>
                    <template v-slot:activator="{ on }">
                      <v-icon color="success" v-on="on">
                        mdi-account
                      </v-icon>
                    </template>
                    <span>{{ cont }}</span>
                  </v-tooltip>
                  <v-tooltip v-if="cont == 'Blocat'" top>
                    <template v-slot:activator="{ on }">
                      <v-icon color="error" v-on="on">
                        mdi-account-lock
                      </v-icon>
                    </template>
                    <span>{{ cont }}</span>
                  </v-tooltip>
                  <v-tooltip v-if="cont == 'Inactiv'" top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-account-off</v-icon>
                    </template>
                    <span>{{ cont }}</span>
                  </v-tooltip>
                  <v-tooltip v-if="cont == 'Suspendat'" top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-account-clock</v-icon>
                    </template>
                    <span>{{ cont }}</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                  {{ insert | limitData }}
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                  {{ visa | limitData }}
                </v-col>
              </v-row> -->
      </v-col>
      <v-col cols="4" sm="2">
        <v-img :src="src" contain width="200" height="100" />
      </v-col>
      <!-- coperta/?pre=cln&rid= -->
    </v-row>
    <v-tabs v-model="selectedTab" show-arrows>
      <v-tab>{{ $t("Împrumuturi active") }}</v-tab>
      <v-tab>{{ $t("Istoric împrumut") }}</v-tab>
      <v-tab>{{ $t("Rezervări") }}</v-tab>
      <v-tab>{{ $t("Istoric rezervări") }}</v-tab>
      <v-tab>{{ $t("Servicii") }}</v-tab>

      <v-tab-item>
        <table-management
          ref="tableloan"
          name="tmdatabasesl"
          table="ebib_tm_lib_loan"
          view-table="5facdd24fbf23ba802de22df"
          row-expand
          :where="`&quot;cln_barcode&quot;:&quot;` + barcodeUser + `&quot;`"
          @select="selectBarcode"
        >
          <template #expanded-item="exp">
            <tr>
              <td class="px-0 py-2" :colspan="exp.headers.length">
                <div>
                  <span class="grey--text">{{ $t("Prelungire:") }}</span>
                  {{ exp.item.date_ext }}
                  <span class="grey--text">{{ $t("Bibliotecar:") }}</span>
                  {{ exp.item.user_ext }}
                </div>
              </td>
            </tr>
          </template>
        </table-management>
      </v-tab-item>
      <v-tab-item>
        <table-management
          ref="tableloanhistory"
          name="tmdatabaseslh"
          table="ebib_tm_lib_loan_history"
          view-table="5facdec1fbf23ba802de22e0"
          :where="`&quot;cln_barcode&quot;:&quot;` + barcodeUser + `&quot;`"
          @select="selectBarcode"
        />
      </v-tab-item>
      <v-tab-item>
        <table-management
          ref="tablereservation"
          name="tmdatabasesr"
          table="ebib_tm_lib_reserve"
          view-table="5facdefdfbf23ba802de22e1"
          :where="`&quot;cln_barcode&quot;:&quot;` + barcodeUser + `&quot;`"
          @select="selectBarcode"
        />
      </v-tab-item>
      <v-tab-item>
        <table-management
          ref="tablereservationhistory"
          name="tmdatabasesrh"
          table="ebib_tm_lib_reserve_history"
          view-table="5facdf26fbf23ba802de22e2"
          :where="`&quot;cln_barcode&quot;:&quot;` + barcodeUser + `&quot;`"
          @select="selectBarcode"
        />
      </v-tab-item>
      <v-tab-item>
        <table-management
          ref="tableservices"
          table="marc_srv"
          :record-default="recordDefault"
          :where="`&quot;fields.500.a.val&quot;:&quot;` + barcodeUser + `&quot;`"
        />
      </v-tab-item>
    </v-tabs>
    <v-row>
      <v-col cols="12">
        <!-- <web-cam ref="cam" :resolution="resolution" height="300" @cameras="listCameras" /> -->
      </v-col>
    </v-row>
    <search-link ref="searchLink" select-event @select="searchSelected" />
    <v-dialog v-model="showDef" persistent max-width="450px">
      <v-card outlined>
        <v-card-title class="pb-2 pt-2 pl-2">
          {{ $t("Definire număr zile și exemplare circulație") }}
          <v-spacer />
          <v-icon color="red" class="mt-n2 mr-n2" large @click="showDef = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6">
              <div><span class="mt-2 mr-2 grey--text">Gestiune:</span>{{ defGestiune }}</div>
              <div><span class="mt-2 mr-2 grey--text">Tip Utilizator:</span>{{ defUtilizator }}</div>
              <div><span class="mt-2 mr-2 grey--text">Regim:</span>{{ defRegim }}</div>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="defDays" type="number" dense :label="$t('Număr zile')" />
              <v-text-field v-model="defItems" type="number" dense :label="$t('Număr exemplare')" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text outlined @click="showDef = false">
            {{ $t("Anulează") }}
          </v-btn>
          <v-btn color="primary" text outlined @click="savenoDef">
            {{ $t("Salvează") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </base-material-card>
</template>
<script>
import axios from "../../plugins/axios"
import { EventBus } from "../../EventBus"
import moment from "moment"
import SearchLink from "../../components/containers/SearchLink"
import SBarcodeReader from "../../components/containers/ScreamBarcodeReader"
import TableManagement from "../../components/containers/TableManagement"
export default {
  name: "Flow",
  components: { SearchLink, TableManagement, SBarcodeReader },
  filters: {
    limitLength(value, len) {
      if (!value) return ""
      value = value.toString()
      if (value.length > len) {
        return value.substring(0, len) + "..."
      }
      return value
    },
    limitData(value) {
      if (value == undefined) return ""
      value = value.toString()
      if (value.length > 10) {
        return value.substring(0, 10)
      }
      return value
    }
  },
  data() {
    return {
      readerCam: false,
      inventarCam: false,
      path: "",
      user: {},
      dense: true,
      autoLoan: true,
      showDef: false,
      defGestiune: "",
      defUtilizator: "",
      defRegim: "",
      defDays: "",
      defItems: "",
      barcode: "",
      barcodeUser: "",
      barcodeInventar: "",
      name: "",
      type: "",
      cont: "",
      card: "",
      insert: "",
      visa: "",
      src: "",
      selectedTab: 0
    }
  },
  computed: {
    recordDefault() {
      if (this.selectedTab == 4) {
        const d = new Date(this.$store.getters.serverDate)
        const d210d = moment(d).format("YYYY-MM-DD hh:mm:ss")
        return {
          fields: {
            210: [
              {
                d: [{ val: d210d }]
              }
            ],
            500: [
              {
                a: [{ val: this.barcodeUser }]
              }
            ]
          }
        }
      }
      return {}
    },
    companyId() {
      return this.$store.getters.companyId
    },

    inputValidate() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    }
  },
  watch: {
    $route(to) {
      if (to.path == this.path && this.barcodeUser != "") {
        this.$log("selected tab ", this.selectedTab)
        this.refreshTableData()
        this.searchReader()
      }
    },
    selectedTab() {
      this.refreshTableData()
    }
  },
  created() {
    this.path = this.$route.path
  },
  mounted() {
    // navigator.mediaDevices
    //   .enumerateDevices()
    //   .then(function(devices) {
    //     devices.forEach(function(device) {
    //       console.log(device.kind + ": " + device.label + " id = " + device.deviceId)
    //     })
    //   })
    //   .catch(function(err) {
    //     console.log(err.name + ": " + err.message)
    //   })
    // const codeReader = new BrowserMultiFormatReader()
    // console.log("ZXingBrowser ", BrowserMultiFormatReader.listVideoInputDevices())
    // codeReader.decodeFromVideoDevice(
    //   "ae4b32e607a1172230fb34b92a8601d04f44ba8123f3ed354f2037a5cea3ff20",
    //   this.$refs.cam.$el,
    //   (result, error, controls) => {
    //     if (result) {
    //       console.log("read1 ", result)
    //     }
    //   }
    // )
    // ZXingBrowser.BrowserCodeReader.listVideoInputDevices().then(videoInputDevices => {
    //   console.log("videoInputDevices ", videoInputDevices)
    // })
    // const selectedDeviceId = videoInputDevices[0].deviceId;
    // console.log(`Started decode from camera with id ${selectedDeviceId}`);
    //  const previewElem = document.querySelector('#test-area-qr-code-webcam > video');
    //  const controls = await codeReader.decodeFromVideoDevice("selectedDeviceId", previewElem, (result, error, controls) => {
    //    console.log("read ",result)
    //  });
  },
  methods: {
    listCameras(e) {
      this.listCamera = e
      if (this.listCamera.length > 0) {
        this.camera = this.listCamera[0]
        this.deviceId = this.camera.deviceId
      }
    },
    logIt(data) {
      console.log("detected", data)
    },
    savenoDef() {
      this.$log("savenoDef")
      axios.get("app_company/" + this.companyId).then(response => {
        this.$log("this library ", response)
        if (response.data.config.library.loan[this.defGestiune]) {
          if (response.data.config.library.loan[this.defGestiune][this.defUtilizator]) {
            response.data.config.library.loan[this.defGestiune][this.defUtilizator][this.defRegim] = {
              days: Number(this.defDays),
              items: Number(this.defItems)
            }
          } else {
            response.data.config.library.loan[this.defGestiune][this.defUtilizator] = {}
            response.data.config.library.loan[this.defGestiune][this.defUtilizator][this.defRegim] = {
              days: Number(this.defDays),
              items: Number(this.defItems)
            }
          }
        } else {
          response.data.config.library.loan[this.defGestiune] = {}
          response.data.config.library.loan[this.defGestiune][this.defUtilizator] = {}
          response.data.config.library.loan[this.defGestiune][this.defUtilizator][this.defRegim] = {
            days: Number(this.defDays),
            items: Number(this.defItems)
          }
        }
        const et = response.data._etag
        response.data._created = undefined
        response.data._links = undefined
        response.data._version = undefined
        response.data._latest_version = undefined
        response.data._updated = undefined
        response.data._status = undefined
        response.data._etag = undefined
        response.data._id = undefined
        axios
          .put("app_company/" + this.companyId, response.data, {
            headers: {
              "If-Match": et
            }
          })
          .then(res => {
            this.$log("update", res)
            if (res.status == 200) {
              this.showDef = false
            }
          })
      })
    },

    refreshTableData() {
      if (this.selectedTab == 0 && this.$refs.tableloan) {
        this.$refs.tableloan.getRecords()
      } else if (this.selectedTab == 1 && this.$refs.tableloanhistory) {
        this.$refs.tableloanhistory.getRecords()
      } else if (this.selectedTab == 2 && this.$refs.tablereservation) {
        this.$refs.tablereservation.getRecords()
      } else if (this.selectedTab == 3 && this.$refs.tablereservationhistory) {
        this.$refs.tablereservationhistory.getRecords()
      } else if (this.selectedTab == 4 && this.$refs.tableservices) {
        this.$refs.tableservices.getRecords()
      }
    },
    selectBarcode(e) {
      //this.$log("selectBarcode ", e)
      if (e.length == 1) {
        this.barcodeInventar = e[0].inv_barcode
      }
    },
    loanEnter() {
      if (this.autoLoan) {
        this.loan()
      }
    },
    //'warn' > portocaliu; 'crit' > 'mov'; 'error' > rosu; 'info' > verde; 'notice' > albastru
    returnAlertColor(level) {
      switch (level) {
        case "warn":
          return "warning"
        case "crit":
          return "purple"
        case "error":
          return "error"
        case "info":
          return "success"
        case "notice":
          return "info"
      }
      return "info"
    },
    loan() {
      if (this.barcodeUser != "" && this.barcodeInventar != "") {
        axios.post("loan/" + this.barcodeUser + "/" + this.barcodeInventar).then(res => {
          this.$log("loan ", res)
          //1009 restiutuire 1010 imprumut
          if (res.data.msg) {
            EventBus.$emit("show-alert", {
              message: res.data.msg,
              more: res.data.extra,
              color: this.returnAlertColor(res.data.level),
              timeOut: 10000
            })
          }
          //8552
          if (res.data.code == 1009 || res.data.code == 1010) {
            this.$refs.tableloan.getRecords()
          }
          if (res.data.code == 8552 || res.data.code == 8553) {
            this.defGestiune = res.data.data.site
            this.defUtilizator = res.data.data.user_class
            this.defRegim = res.data.data.regime
            this.defDays = ""
            this.defItems = ""
            this.showDef = true

            this.$log("gestiune ", res.data.data.site)
            this.$log("tiputi ", res.data.data.user_class)
            this.$log("regim ", res.data.data.regime)
          }
        })
        this.barcodeInventar = ""
      }
    },
    extend() {
      if (this.barcodeUser != "" && this.barcodeInventar != "") {
        axios.post("extend/" + this.barcodeUser + "/" + this.barcodeInventar).then(res => {
          this.$log("extend ", res)
          if (res.data.msg) {
            EventBus.$emit("show-alert", {
              message: res.data.msg,
              more: res.data.extra,
              color: this.returnAlertColor(res.data.level),
              timeOut: 10000
            })
          }
        })
        this.barcodeInventar = ""
      }
    },
    reserve() {
      if (this.barcodeUser != "" && this.barcodeInventar != "") {
        axios.post("reserve/" + this.barcodeUser + "/" + this.barcodeInventar).then(res => {
          this.$log("reserve ", res)
          if (res.data.msg) {
            EventBus.$emit("show-alert", {
              message: res.data.msg,
              more: res.data.extra,
              color: this.returnAlertColor(res.data.level),
              timeOut: 10000
            })
          }
          if (res.data.code == 1009 || res.data.code == 1010) {
            this.$refs.tablereservation.getRecords()
          }
        })
        this.barcodeInventar = ""
      }
    },
    searchRelation() {
      this.$refs.searchLink.open("ebib_marc_cln", null, "flow", { where: '"ctg":"reader"' })
    },
    searchRelationInv() {
      this.$refs.searchLink.open("ebib_marc_inv", null, "flowinv", { where: '"ctg":"inventar"' })
    },
    searchReader() {
      if (this.barcode != "") {
        axios.get('ebib_marc_cln?where={"fields.500.a.val":"' + this.barcode + '"}').then(res => {
          if (res.data._items.length > 0) {
            this.searchSelected(res.data._items[0])
          } else {
            EventBus.$emit("show-alert", {
              message: this.$t("Nu a fost găsit nici un cititor cu barcode") + " " + this.barcode + ".",
              timeOut: 5000
            })
          }
        })
      }
    },
    searchSelected(e) {
      this.$log("searchSelected ", e)
      if (e.ctg == "reader") {
        this.user = e
        this.barcode = e.fields["500"][0].a[0].val
        this.barcodeUser = e.fields["500"][0].a[0].val
        this.name = e.fields["200"][0].a[0].val
        this.type = e.fields["505"][0].a[0].val
        this.cont = e.fields["505"][0].c[0].val
        this.card = e.fields["505"][0].d[0].val
        if (e.fields["505"][0].b) {
          this.insert = e.fields["505"][0].b[0].val
        } else {
          this.insert = ""
        }

        this.$log("typ1 ", typeof this.cont)
        this.$log("typ2 ", this.cont)
        this.$log("typ3 ", this.cont == "Activ")
        if (e.fields["505"][0].e) {
          this.visa = e.fields["505"][0].e[0].val
        }
        this.src = axios.defaults.baseURL + "coperta/?pre=cln&rid=" + e._id
        this.$nextTick(() => {
          this.$refs.barcodeInventar.focus()
        })
      } else {
        this.barcodeInventar = e.fields["200"][0].a[0].val
        this.$nextTick(() => {
          this.$refs.barcodeInventar.focus()
        })
      }
    },
    closeSheet() {
      this.barcodeInventar = ""
      this.barcode = ""
      this.barcodeUser = ""
      this.name = ""
      this.type = ""
      this.cont = ""
      this.card = ""
      this.insert = ""
      this.visa = ""
      this.src = ""
    }
  }
}
</script>
<!--style scoped>
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
  height: 24px;
}
</style-->
